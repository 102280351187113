import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { formToObject } from 'utils/formToObject'
import { login, getCSRFToken } from 'utils/Auth'

import neocastLogo from 'images/neocast-logo.svg'
import { forgotMyPasswordPath, homePath } from 'Routes'
import { Translate } from 'components/Utils/Translate'
import { translateRaw } from 'components/Utils/translateRaw'
import { loadOnlyCustomer } from 'components/BackendAPI/BackendAPI'
import { User } from 'neocast-api-js-client'
import { clearLoginRedirectInfo, getLoginRedirectInfo, setLoggedIn } from 'utils/loggedInStatus'
import { useReloadAppWhenNeeded } from 'hooks'
import { defaultLogin, defaultPassword } from 'env'

export const LoginForm = () => {
  const history = useHistory()
  const [error, setError] = useState('')
  const appReloadIsNeeded = useReloadAppWhenNeeded()

  // Force a full page reload if we notice the reload app is needed
  //
  // This handles a case when the user has been sitting at the login
  // page after a forced logout and the code on the server has been
  // updated.
  useEffect(() => {
    if (appReloadIsNeeded) {
      window.location.reload()
    }
  }, [appReloadIsNeeded])

  const onLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = formToObject(form, new User())

    const user = {
      login: formData.login,
      password: formData.password,
    }

    login({
      user,
      onSuccess: async () => {
        // Set axios default upon login
        axios.defaults.headers.common['X-CSRF-Token'] = getCSRFToken()

        const { pathname, search } = getLoginRedirectInfo()
        clearLoginRedirectInfo()

        window.scrollTo(0, 0)

        // Fetch the customer for any needed customizations
        // perhaps we should serialize all of the custom bits
        // for the customer?
        const customer = (await loadOnlyCustomer())[0]
        if (customer?.usesCustomBannerColor && customer?.bannerColor) {
          localStorage.setItem('bannerColor', customer.bannerColor)
        } else {
          localStorage.removeItem('bannerColor')
        }

        // @ts-ignore
        document.body.style = ''

        setLoggedIn()

        if (pathname) {
          history.push(`${pathname}${search}`)
        } else {
          history.push(homePath())
        }
      },
      onError: () => {
        setError(translateRaw('login-page-invalid-username-or-password'))
      },
    })
  }

  return (
    <div className="full-dialog-page d-flex align-items-center">
      <div className="flex-grow-1">
        <div>
          <div className="col-6 col-md-2 mx-auto">
            <img alt="NEOCAST logo" className="w-100 mb-3" src={neocastLogo} />
          </div>
        </div>
        <div>
          <div className="col-8 col-md-3 mx-auto">
            <div>
              <div>
                <form className="form" autoComplete="off" onSubmit={onLogin}>
                  <div className="form-group">
                    <input
                      type="text"
                      defaultValue={defaultLogin}
                      autoFocus
                      placeholder={translateRaw('login-page-username')}
                      className="form-control form-control-lg"
                      name="login"
                      id="login"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      defaultValue={defaultPassword}
                      placeholder={translateRaw('login-page-password')}
                      className="form-control form-control-lg"
                      name="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="form-group">
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                  </div>
                  <div>
                    <button type="submit" className="w-100 btn btn-primary btn-lg mb-2" id="btnLogin">
                      {Translate('login-page-buttons-log-in')}
                    </button>
                    <Link to={forgotMyPasswordPath()}>{Translate('login-page-forgot-password')}</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
