import { RoutePathFunction } from './types'
import { paramsReplace } from './utils'

export const mediaGroupIndexPath: RoutePathFunction = params =>
  paramsReplace('/media/media-groups/:media_group_id', params)

export const mediaGroupIndexPathWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/media/media-groups/:media_group_id?rightbar=:class-:id', params)

export const mediaSmartGroupIndexPath: RoutePathFunction = params =>
  paramsReplace('/media/smart-groups/:smart_group_id', params)

export const mediaSmartGroupIndexWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/media/smart-groups/:smart_group_id?rightbar=:class-:id', params)

export const newMediaPath: RoutePathFunction = params => paramsReplace('/media/new/:type', params)

export const mediaIndexAsTablePath: RoutePathFunction = params => paramsReplace('/media/table', params)

export const editMediaPath: RoutePathFunction = params => paramsReplace('/media/:id', params)

export const mediaIndexPath: RoutePathFunction = params => paramsReplace('/media', params)

export const mediaIndexWithTypePath: RoutePathFunction = params => paramsReplace('/media/type/:type', params)

export const mediaIndexWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/media?rightbar=:class-:id', params)

export const newMediaGroupPath: RoutePathFunction = params => paramsReplace('/media-groups/new', params)

export const editMediaGroupPath: RoutePathFunction = params => paramsReplace('/media-groups/:id', params)

export const editSmartGroupPath: RoutePathFunction = params => paramsReplace('/smart-groups/:type/:id', params)

export const mediaSmartGroupsIndexPath: RoutePathFunction = params => paramsReplace('/media/smart-groups', params)

export const mediaSmartGroupsIndexPathWithRightBar: RoutePathFunction = params =>
  paramsReplace('/media/smart-groups?rightbar=SmartGroup-:smart_group_id', params)

export const mediaStaticGroupsIndexPath: RoutePathFunction = params => paramsReplace('/media/media-groups', params)

export const mediaStaticGroupsIndexPathWithRightBar: RoutePathFunction = params =>
  paramsReplace('/media/media-groups?rightbar=MediaGroup-:media_group_id', params)

export const mediaFoldersIndexPath: RoutePathFunction = params => paramsReplace('/media/folders', params)
export const mediaFoldersIndexPathWithRightBar: RoutePathFunction = params =>
  paramsReplace('/media/folders?rightbar=GroupCategory-:folder_id', params)

export const neocastUploaderInitPath: RoutePathFunction = () => {
  return '/neocast_uploader/init'
}

export const neocastUploaderCheckPath: RoutePathFunction = () => {
  return '/neocast_uploader/check'
}

export const neocastUploaderUploadChunkPath: RoutePathFunction = params => {
  return paramsReplace('/neocast_uploader/upload/:id/:currentChunk', params)
}

export const neocastUploaderCreatePath: RoutePathFunction = params => {
  return paramsReplace('/neocast_uploader/create/:id', params)
}

export const neocastUploaderThumbnailPath: RoutePathFunction = params => {
  return paramsReplace('/neocast_uploader/thumbnail/:id', params)
}

export const neocastUploaderMetadataPath: RoutePathFunction = params => {
  return paramsReplace('/neocast_uploader/metadata/:id', params)
}

export const mediaStaticGroupsInFolderIndexPath: RoutePathFunction = params =>
  paramsReplace('/media/folders/:folder_id/media-groups', params)

export const mediaSmartGroupsInFolderIndexPath: RoutePathFunction = params =>
  paramsReplace('/media/folders/:folder_id/smart-groups', params)
export const allMediaIndexPaths = [
  mediaFoldersIndexPath(),
  mediaGroupIndexPath(),
  mediaGroupIndexPathWithRightBarPath(),
  mediaIndexAsTablePath(),
  mediaIndexPath(),
  mediaIndexWithRightBarPath(),
  mediaIndexWithTypePath(),
  mediaSmartGroupIndexPath(),
  mediaSmartGroupIndexWithRightBarPath(),
  mediaSmartGroupsInFolderIndexPath(),
  mediaSmartGroupsIndexPath(),
  mediaSmartGroupsIndexPathWithRightBar(),
  mediaStaticGroupsInFolderIndexPath(),
  mediaStaticGroupsIndexPath(),
  mediaStaticGroupsIndexPathWithRightBar(),
]

export const allMediaPaths = [
  editMediaGroupPath(),
  editMediaPath(),
  editSmartGroupPath({ type: 'media' }),
  mediaFoldersIndexPath(),
  mediaGroupIndexPath(),
  mediaGroupIndexPathWithRightBarPath(),
  mediaIndexAsTablePath(),
  mediaIndexPath(),
  mediaIndexPath(),
  mediaIndexWithRightBarPath(),
  mediaIndexWithTypePath(),
  mediaSmartGroupIndexPath(),
  mediaSmartGroupIndexWithRightBarPath(),
  mediaSmartGroupsIndexPath(),
  mediaSmartGroupsIndexPathWithRightBar(),
  mediaSmartGroupsInFolderIndexPath(),
  mediaStaticGroupsIndexPath(),
  mediaStaticGroupsIndexPathWithRightBar(),
  mediaStaticGroupsInFolderIndexPath(),
  neocastUploaderCheckPath(),
  neocastUploaderCreatePath(),
  neocastUploaderInitPath(),
  neocastUploaderMetadataPath(),
  neocastUploaderThumbnailPath(),
  neocastUploaderUploadChunkPath(),
  newMediaGroupPath(),
  newMediaPath(),
]
