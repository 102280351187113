export const NODE_ENV = process.env.NODE_ENV

export const isProduction = NODE_ENV === 'production'
export const isDevelopment = NODE_ENV === 'development'
export const isTest = Number(process.env.VITEST_WORKER_ID) > 0
export const googleMapsApiKeyFromEnv = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
export const NeocastVersion = process.env.REACT_APP_NEOCAST_VERSION || 'Development'
export const CommitSHA = process.env.REACT_APP_COMMIT_SHA || ''
export const defaultLogin = process.env.REACT_APP_DEFAULT_LOGIN || ''
export const defaultPassword = process.env.REACT_APP_DEFAULT_PASSWORD || ''
