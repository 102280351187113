import { editSmartGroupPath } from './mediaRoutes'
import { RoutePathFunction } from './types'
import { paramsReplace } from './utils'

export const presentationSmartGroupsIndexPath: RoutePathFunction = params =>
  paramsReplace('/presentations/smart-groups', params)

export const presentationSmartGroupsIndexPathWithRightBar: RoutePathFunction = params =>
  paramsReplace('/presentations/smart-groups?rightbar=SmartGroup-:smart_group_id', params)

export const presentationSmartGroupsInFolderIndexPath: RoutePathFunction = params =>
  paramsReplace('/presentations/folders/:folder_id/smart-groups', params)

export const presentationSmartGroupIndexPath: RoutePathFunction = params =>
  paramsReplace('/presentations/smart-groups/:smart_group_id', params)

export const presentationSmartGroupIndexWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/presentations/smart-groups/:smart_group_id?rightbar=:class-:id', params)

export const presentationFoldersIndexPath: RoutePathFunction = params => paramsReplace('/presentations/folders', params)
export const presentationFoldersIndexPathWithRightBar: RoutePathFunction = params =>
  paramsReplace('/presentations/folders?rightbar=GroupCategory-:folder_id', params)

export const presentationIndexPath: RoutePathFunction = params => paramsReplace('/presentations', params)

export const editPresentationPath: RoutePathFunction = params => paramsReplace('/presentations/:id', params)

export const editPresentationDetailsPath: RoutePathFunction = params =>
  paramsReplace('/presentations/:id/details', params)

export const duplicatePresentationPath: RoutePathFunction = params =>
  paramsReplace('/presentations/:id/duplicate', params)

export function presentationDetailsPath() {
  return '/api/v1/presentation_details'
}

export const newPresentationPath: RoutePathFunction = params => {
  return paramsReplace('/presentations/new', params)
}
export const allPresentationPaths = [
  editPresentationDetailsPath(),
  editPresentationPath(),
  editSmartGroupPath({ type: 'presentation' }),
  presentationFoldersIndexPath(),
  presentationIndexPath(),
  presentationSmartGroupIndexPath(),
  duplicatePresentationPath(),
  editPresentationDetailsPath(),
  editPresentationPath(),
  newPresentationPath(),
  presentationFoldersIndexPath(),
  presentationIndexPath(),
  presentationSmartGroupIndexPath(),
  presentationSmartGroupIndexWithRightBarPath(),
  presentationSmartGroupsIndexPath(),
  presentationSmartGroupsIndexPathWithRightBar(),
  presentationSmartGroupsInFolderIndexPath(),
]
