import honeybadger from '@honeybadger-io/js'
import { AxiosResponse } from 'axios'

// The local storage key to record if the user is logged in
const LOGGED_IN_KEY = 'loggedin'
// The local storage key to record the Date.now epoch when the current client token will be expired
const CLIENT_TIMEOUT_EPOCH_KEY = 'clientTimeoutEpoch'

export const X_NO_CLIENT_TIMEOUT_REFRESH_NAME = 'X-No-Client-Timeout-Refresh'
export const X_NO_CLIENT_TIMEOUT_REFRESH_HEADER = { X_NO_CLIENT_TIMEOUT_REFRESH_NAME: 'true' }

export function getLoggedIn() {
  // A user is logged in if the local storage says so *AND* the client timeout hasn't expired
  return localStorage.getItem(LOGGED_IN_KEY) === 'true' && !isClientTimeoutExpired()
}

export function clearLoggedIn() {
  localStorage.removeItem(LOGGED_IN_KEY)
}

export function setLoggedIn() {
  localStorage.setItem(LOGGED_IN_KEY, 'true')
}

export function recordClientTimeoutSecondsRemainingFromAxiosResponse(response: AxiosResponse<any>) {
  if (response.headers && response.headers['x-client-expires-in-seconds']) {
    recordClientTimeoutSecondsRemaining(Number(response.headers['x-client-expires-in-seconds']))
  }
}

export function recordClientTimeoutSecondsRemainingFromResponse(response: Response) {
  if (response.headers && response.headers.get('x-client-expires-in-seconds')) {
    recordClientTimeoutSecondsRemaining(Number(response.headers.get('x-client-expires-in-seconds')))
  }
}

export function recordClientTimeoutSecondsRemaining(secondsRemaining: number) {
  honeybadger.setContext({ setClientTimeoutSecondsRemaining: secondsRemaining })

  const newEpoch = Date.now() + 1000 * secondsRemaining
  localStorage.setItem(CLIENT_TIMEOUT_EPOCH_KEY, newEpoch.toString())
}

export function getClientTimeoutSecondsRemaining() {
  const epoch = Number(localStorage.getItem(CLIENT_TIMEOUT_EPOCH_KEY))

  return Math.ceil((epoch - Date.now()) / 1000)
}

export function isClientTimeoutWarningNeeded() {
  return getClientTimeoutSecondsRemaining() < 30
}

export function isClientTimeoutExpired() {
  return getClientTimeoutSecondsRemaining() <= 0
}

export function saveLoginRedirectInfo() {
  window.localStorage.setItem('redirect-to-pathname', window.location.pathname)
  window.localStorage.setItem('redirect-to-search', window.location.search)
}

export function getLoginRedirectInfo() {
  const pathname = window.localStorage.getItem('redirect-to-pathname')
  const search = window.localStorage.getItem('redirect-to-search')

  return { pathname, search }
}

export function clearLoginRedirectInfo() {
  window.localStorage.removeItem('redirect-to-pathname')
  window.localStorage.removeItem('redirect-to-search')
}
