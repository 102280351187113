import {
  EvaluationCondition,
  LayoutElementType,
  PresentationEditState,
  PresentationDetails,
  PresentationDetailsMedia,
  ZoneContinuation,
  ZoneContinuationEnd,
  ZoneContinuationMiddle,
  ZoneContinuationStart,
  ZoneType,
  ZoneWithContinuation,
  ZoneWithContinuationEnd,
  ZoneWithContinuationMiddle,
  ZoneWithContinuationStart,
  ZoneWithoutContinuation,
  PresentationDetailsMediaGroup,
  PresentationDetailsSmartGroup,
} from './types'
import { LayoutEditorPresentation, ZoneContentItem, ZoneDimensionsType } from './LayoutEditorPresentation'
import { TranslationKey } from 'static-data/TranslationKey'
import { reducedFraction } from 'utils'
import { translateRaw } from 'components/Utils/translateRaw'
import { noPreviewAvailableThumbnail, noThumbnailAvailable } from 'images'

export function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here")
}

export function layoutHasContinuations(layout: LayoutElementType) {
  return layout.zones.some(zone => isZoneContinuation(zone))
}

export function layoutHasContinuationsToNext(layout: LayoutElementType) {
  return layout.zones.some(zone => isZoneContinuationStart(zone) || isZoneContinuationMiddle(zone))
}

export function zonesSortedByZOrder({
  layouts,
  layout,
  zones,
}: {
  layouts: LayoutElementType[]
  layout: LayoutElementType
  zones: ZoneType[]
}): ZoneType[] {
  return [...zones].sort((a, b) => {
    const orderSort = b.z_order - a.z_order

    // If they have the same z order, sort by name
    if (orderSort === 0) {
      return zoneName({ layouts, layout, zone: b }).localeCompare(zoneName({ layouts, layout, zone: a }))
    }

    return orderSort
  })
}

export function contentIsZoneContinuationStart(contents: ZoneContinuation): contents is ZoneContinuationStart {
  return (
    Object.prototype.hasOwnProperty.call(contents, 'id') === true &&
    Object.prototype.hasOwnProperty.call(contents, 'to') === true &&
    Object.prototype.hasOwnProperty.call(contents, 'from') === false
  )
}

export function contentIsZoneContinuationMiddle(contents: ZoneContinuation): contents is ZoneContinuationMiddle {
  return (
    Object.prototype.hasOwnProperty.call(contents, 'id') === true &&
    Object.prototype.hasOwnProperty.call(contents, 'to') === true &&
    Object.prototype.hasOwnProperty.call(contents, 'from') === true
  )
}

export function contentIsZoneContinuationEnd(
  contents: ZoneContinuationStart | ZoneContinuationMiddle | ZoneContinuationEnd
): contents is ZoneContinuationEnd {
  return (
    Object.prototype.hasOwnProperty.call(contents, 'id') === true &&
    Object.prototype.hasOwnProperty.call(contents, 'to') === false &&
    Object.prototype.hasOwnProperty.call(contents, 'from') === true
  )
}

export function isZoneContinuationStart(zone: ZoneType): zone is ZoneWithContinuationStart {
  if (!isZoneContinuation(zone)) {
    return false
  }

  return contentIsZoneContinuationStart(zone.contents)
}

export function isZoneContinuationMiddle(zone: ZoneType): zone is ZoneWithContinuationMiddle {
  if (!isZoneContinuation(zone)) {
    return false
  }

  return contentIsZoneContinuationMiddle(zone.contents)
}

export function isZoneContinuationEnd(zone: ZoneWithContinuation): zone is ZoneWithContinuationEnd {
  if (!isZoneContinuation(zone)) {
    return false
  }

  return contentIsZoneContinuationEnd(zone.contents)
}

export function isZoneWithoutContinuation(zone: ZoneType): zone is ZoneWithoutContinuation {
  return !isZoneContinuation(zone)
}

export function isZoneContinuation(zone: ZoneType): zone is ZoneWithContinuation {
  // If the contents is an array, it's not a continuation
  if (Array.isArray(zone.contents)) {
    return false
  }

  return (
    contentIsZoneContinuationStart(zone.contents) ||
    contentIsZoneContinuationMiddle(zone.contents) ||
    contentIsZoneContinuationEnd(zone.contents)
  )
}

export function zoneForFetchingName({
  layouts,
  layout,
  zone,
}: {
  zone: ZoneType
  layouts: LayoutElementType[]
  layout: LayoutElementType
}): ZoneType | undefined {
  let zoneForName = zone
  if (isZoneContinuation(zone)) {
    const { zone: zoneAtStartOfContinuation } = findStartOfContinuation({ layouts, layout, zone })
    if (!zoneAtStartOfContinuation) {
      return undefined
    }

    zoneForName = zoneAtStartOfContinuation
  }

  return zoneForName
}

export function zoneHasCustomizedName({
  layouts,
  layout,
  zone,
}: {
  zone: ZoneType
  layouts: LayoutElementType[]
  layout: LayoutElementType
}) {
  const zoneForName = zoneForFetchingName({ layouts, layout, zone })

  return zoneForName && zoneForName.name
}

export function zoneNameFromId({ layout, zoneId }: { layout: LayoutElementType; zoneId: number }) {
  const zone = layout.zones.find(zone => zone.id === zoneId)
  if (zone === undefined) {
    return 'unknown'
  }

  return zone.name ?? `Zone ${zone.id}`
}

export function zoneName({
  layouts,
  layout,
  zone,
}: {
  zone: ZoneType
  layouts: LayoutElementType[]
  layout: LayoutElementType
}) {
  const zoneForName = zoneForFetchingName({ layouts, layout, zone })
  if (!zoneForName) {
    return 'Unknown'
  }

  return zoneForName.name ?? `Zone ${zoneForName.id}`
}

export function presentationTotalWidthHeight(presentation: LayoutEditorPresentation) {
  const width = presentation.size.array.x * presentation.size.screen.x
  const height = presentation.size.array.y * presentation.size.screen.y

  return { width, height }
}

export function canRenameZone({
  layouts,
  layout,
  zone,
  name,
}: {
  layouts: LayoutElementType[]
  layout: LayoutElementType
  zone: ZoneType
  name: string
}) {
  // If this is a continuation, we need to find the start of the continuation
  // as this is the actual zone we are renaming.
  if (isZoneContinuation(zone)) {
    const { layout: foundLayout, zone: foundZone } = findStartOfContinuation({
      zone,
      layout,
      layouts,
    })
    if (!foundLayout || !foundZone) {
      return false
    }

    layout = foundLayout
    zone = foundZone
  }

  // Go through all the layouts that this zone might be a part of and check if this name is duplicated
  const layoutsAndZones = continuationLayoutsAndZonesStartingHere({ layouts, layout, zone })
  for (const layoutAndZone of layoutsAndZones) {
    const otherZones = layoutAndZone.layout.zones.filter(zone => zone.id !== layoutAndZone.zone.id)
    const otherZoneNames = otherZones.map(zone => zoneName({ layouts, layout: layoutAndZone.layout, zone }))

    if (otherZoneNames.includes(name)) {
      return false
    }
  }

  return true
}

export function findStartOfContinuation({
  layouts,
  layout,
  zone,
}: {
  layouts: LayoutElementType[]
  layout: LayoutElementType
  zone: ZoneType
}): { layout: LayoutElementType; zone: ZoneType } | { layout: undefined; zone: undefined } {
  if (!isZoneContinuation(zone)) {
    return { zone: undefined, layout: undefined }
  }

  let currentLayout = layout
  let currentLayoutIndex = layouts.findIndex(aLayout => aLayout.id === currentLayout.id)
  let currentZone = zone
  while (!isZoneContinuationStart(currentZone)) {
    const workingZone = currentZone

    if (currentLayoutIndex === 0) {
      return { zone: undefined, layout: undefined }
    }

    currentLayoutIndex -= 1
    currentLayout = layouts[currentLayoutIndex]

    const newZone = currentLayout.zones.find(zone => zone.id === workingZone.contents.from)
    if (!newZone) {
      return { zone: undefined, layout: undefined }
    }

    currentZone = newZone as unknown as ZoneWithContinuationStart | ZoneWithContinuationMiddle
  }

  return { layout: currentLayout, zone: currentZone }
}

// If the current zone is not a continuation, return the supplied layout and zone
// Otherwise returns an array of layout and zones representing the continuations
// starting with the supplied layout and zone
export function continuationLayoutsAndZonesStartingHere({
  layouts,
  layout,
  zone,
}: {
  layouts: LayoutElementType[]
  layout: LayoutElementType
  zone: ZoneType
}): { layout: LayoutElementType; zone: ZoneType }[] {
  let results = [{ layout, zone }]

  if (isZoneWithoutContinuation(zone)) {
    return results
  }

  let layoutIndex = layouts.findIndex(aLayout => aLayout.id === layout.id)

  let currentZone: ZoneWithContinuation = zone
  while (currentZone !== undefined) {
    // This if is weird, but it helps with type inference
    if (!isZoneContinuationStart(currentZone) && !isZoneContinuationMiddle(currentZone)) {
      break
    }

    layoutIndex += 1
    results = [...results, { layout: layouts[layoutIndex], zone: currentZone }]

    if (layoutIndex >= layouts.length) {
      break
    }

    // This assignment is just to clearly resolve types
    const startOrMiddleZone = currentZone
    const nextZone = layouts[layoutIndex].zones.find(nextZone => nextZone.id === startOrMiddleZone.contents.to)

    // This if is weird, but it helps with type inference
    if (!nextZone || (!isZoneContinuationStart(nextZone) && !isZoneContinuationMiddle(nextZone))) {
      break
    }

    currentZone = nextZone
  }

  return results
}

export function findLayoutById({ state, layoutId }: { state: PresentationEditState; layoutId: number }): {
  layout: LayoutElementType | undefined
} {
  const {
    presentation: { layouts },
  } = state

  return { layout: layouts.find(layout => layout.id === layoutId) }
}

export function findLayoutAndZoneById({
  state,
  layoutId,
  zoneId,
}: {
  state: PresentationEditState
  layoutId: number
  zoneId: number
}): { layout: LayoutElementType | undefined; zone: ZoneType | undefined } {
  const { layout } = findLayoutById({ state, layoutId })

  const zone = layout?.zones.find(zone => zone.id === zoneId)

  return { layout, zone }
}

export function zoneNameCollisionInNextLayout({
  layouts,
  layout,
  zonesName,
}: {
  layouts: LayoutElementType[]
  layout: LayoutElementType
  zonesName: string
}) {
  const layoutIndex = layouts.findIndex(aLayout => aLayout.id === layout.id)
  if (layoutIndex === -1 || layoutIndex === layouts.length - 1) {
    return false
  }

  const nextLayout = layouts[layoutIndex + 1]

  return nextLayout.zones.some(aZone => zoneName({ layouts, layout: nextLayout, zone: aZone }) === zonesName)
}

export function zoneNameMatchesIllegalPattern(name: string) {
  return Boolean(name.match(/^Zone \d+$/i))
}

export function dataConditionToJson(dataConditionJson: string) {
  try {
    return (JSON.parse(dataConditionJson)?.evaluations || []) as EvaluationCondition[]
  } catch {
    return []
  }
}

export const NotActive = Symbol('NotActive')
export const MismatchAspectRatio = Symbol('MismatchAspectRatio')

export function errorDetailsForMedia({
  details,
  zoneWidth,
  zoneHeight,
}: {
  details: PresentationDetailsMedia
  zoneWidth: number
  zoneHeight: number
}) {
  let errors = []

  if (details.playableAt) {
    const playableAt = new Date(details.playableAt)

    if (new Date() < playableAt) {
      errors.push(NotActive)
    }
  }

  if (details.noLongerPlayableAt) {
    const noLongerPlayableAt = new Date(details.noLongerPlayableAt)

    if (new Date() > noLongerPlayableAt) {
      errors.push(NotActive)
    }
  }

  if (details.width && details.height) {
    const mediaAspectRatio = reducedFraction(details.width, details.height).join('/')
    const zoneAspectRatio = reducedFraction(zoneWidth, zoneHeight).join('/')

    if (mediaAspectRatio !== zoneAspectRatio) {
      errors.push(MismatchAspectRatio)
    }
  }

  return errors
}

const nullZoneContentDetails = {
  id: 0,
  name: 'NOT FOUND IN THE LOOKUP! THIS IS AN ERROR',
  type: 'video',
  runTime: 0,
  cachedFileSize: 0,
  playableAt: '',
  noLongerPlayableAt: '',
  thumbnailUrl: noThumbnailAvailable,
  previewUrl: noPreviewAvailableThumbnail,
  width: 1,
  height: 1,
  preview: '',
  fileName: 'null',
  fileSize: 1,
}

export function lookupZoneContentDetails({
  zoneContent,
  presentationDetails,
}: {
  zoneContent: ZoneContentItem
  presentationDetails: PresentationDetails
}) {
  switch (zoneContent.type) {
    case 'media':
    case 'sync': {
      const found = presentationDetails.media[zoneContent.id] || {}
      return { ...nullZoneContentDetails, ...found } as PresentationDetailsMedia
    }
    case 'media_group': {
      const found = presentationDetails.mediaGroups[zoneContent.id] || {}
      return { ...nullZoneContentDetails, ...found } as PresentationDetailsMediaGroup
    }
    case 'smart_group': {
      const found = presentationDetails.smartGroups[zoneContent.id] || {}
      return { ...nullZoneContentDetails, ...found } as PresentationDetailsSmartGroup
    }
  }

  return nullZoneContentDetails as PresentationDetailsMedia
}

export function mediaTypeToTranslation({ type }: { type: string }) {
  return translateRaw(`api-media-type-${type.toLowerCase()}` as TranslationKey)
}

export function zoneDimensions(zone: ZoneType): ZoneDimensionsType {
  const { width, height, x, y } = zone

  return { width, height, x, y }
}

export function isKeyZone({ layout, zone }: { layout: LayoutElementType; zone: ZoneType }) {
  return layout.key_zone_id === zone.id
}

export function zoneCannotStartContinuation({ zone }: { zone: ZoneType }) {
  return isZoneContinuationStart(zone) || isZoneContinuationMiddle(zone)
}

export function zoneHasActiveWarnings(
  isGroup: boolean,
  zoneContentDetails: PresentationDetailsMedia | PresentationDetailsMediaGroup | PresentationDetailsSmartGroup,
  zone: ZoneType
) {
  if (isGroup) {
    const groupDetails = zoneContentDetails as unknown as PresentationDetailsMediaGroup | PresentationDetailsSmartGroup

    return groupDetails.members.some(
      member => errorDetailsForMedia({ details: member, zoneWidth: zone.width, zoneHeight: zone.height }).length > 0
    )
  }

  return (
    errorDetailsForMedia({
      details: zoneContentDetails as PresentationDetailsMedia,
      zoneWidth: zone.width,
      zoneHeight: zone.height,
    }).length > 0
  )
}
