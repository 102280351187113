import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Switch, Route, useHistory, useParams, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Image from 'components/Utils/GracefulImage'
import cx from 'classnames'
import { isProduction } from 'env'

import neocastLogo from 'images/neocast-on-demand-logo.png'

import { durationSecondsToString } from 'utils'
import { onDemandRemotePath } from 'Routes'

const ON_DEMAND_SECURITY_CODE_ITEM_KEY = 'on-demand-security-code'

const SERVER_ROOT_URL = isProduction
  ? '/api/v1/on-demand-remote-control/'
  : 'http://localhost:3001/api/v1/on-demand-remote-control/'
const getClientRootUrl = () => onDemandRemotePath()

const storageForCustomizations = () => sessionStorage

const useOnDemandCustomization = () => {
  const storageKey = `neocast-on-demand-access-code-customization`
  const cachedAccessCodeCustomizations = storageForCustomizations().getItem(storageKey)

  const [customizations, setCustomizations] = useState({
    onDemandTitleBackgroundColor: '',
    onDemandTitleTextColor: '',
    onDemandTitleText: '',
    onDemandRemoteControlsBgColor: '',
    onDemandRemoteControlsTextColor: '',
    onDemandLogoUrl: '',
    loaded: false,
  })

  const reloadCustomizations = useCallback(() => {
    const getCustomizations = async () => {
      const url = `${SERVER_ROOT_URL}/customizations/`

      const { data } = await axios.get(url)

      const customizations = { ...data, loaded: true }

      storageForCustomizations().setItem(storageKey, JSON.stringify(customizations))
      setCustomizations(customizations)
    }

    getCustomizations()
  }, [storageKey])

  useEffect(() => {
    if (cachedAccessCodeCustomizations) {
      setCustomizations({ ...JSON.parse(cachedAccessCodeCustomizations), loaded: true })
      return
    }

    reloadCustomizations()
  }, [reloadCustomizations, cachedAccessCodeCustomizations])

  return { customizations, reloadCustomizations }
}

const LoginPage = ({ accessCodeFromQueryParams = '' }) => {
  const { customizations, reloadCustomizations } = useOnDemandCustomization()

  const [accessCode, setAccessCode] = useState(accessCodeFromQueryParams)

  const [error, setError] = useState()

  const login = async () => {
    const response = await axios.get(`${SERVER_ROOT_URL}/${accessCode}/players`)

    if (response && response.data.error) {
      setError(response.data.error)
    } else {
      storageForCustomizations().setItem(ON_DEMAND_SECURITY_CODE_ITEM_KEY, accessCode)

      window.location.href = `${getClientRootUrl()}/players`
    }
  }

  useEffect(function () {
    reloadCustomizations()
  }, [])

  if (!customizations.loaded) {
    return <></>
  }

  return (
    <div className="container-fluid">
      <div className="row pt-7">
        <div className="col-6 offset-3">
          <div className="card card-square">
            <div
              className="card-header text-center p-3"
              style={{
                backgroundColor: customizations.onDemandTitleBackgroundColor,
                color: customizations.onDemandTitleTextColor,
              }}
            >
              {customizations.onDemandTitleText}
            </div>
            <div className="card-body">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="far fa-lock-alt fa-2x"></i>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control shadowless placeholder-light p-4"
                  placeholder="Access Code"
                  value={accessCode}
                  onChange={event => setAccessCode(event.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <i className="fas fa-question-circle fa-2x"></i>
                  </div>
                </div>
              </div>
              {error && (
                <div className="alert alert-danger mt-4 mb-0" role="alert">
                  {error}
                </div>
              )}
              <button className="btn btn-primary w-100 mt-4 py-3" onClick={login}>
                <i className="fas fa-sign-in-alt pr-2"></i>Login
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 offset-3">
          <div className="text-center">
            <Image className="mw-100" alt="logo" src={customizations.onDemandLogoUrl} errorImage={neocastLogo} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Player = ({ player }) => {
  return (
    <div className="col-xs-12 col-sm-6 col-md-4 my-3">
      <Link to={`${getClientRootUrl()}/player/${player.id}`}>
        <div className="d-flex border">
          <div className="text-body py-3 px-3 border-right rounded-left">
            <i className="fas fa-hdd fa-fw"></i>
          </div>
          <div className="pl-3 py-3 bg-white w-100 rounded-right">{player.name}</div>
        </div>
      </Link>
    </div>
  )
}

const LayoutWithHeader = ({ children, customizations }) => {
  const onClickLogout = () => {
    storageForCustomizations().removeItem(ON_DEMAND_SECURITY_CODE_ITEM_KEY)

    window.location.href = getClientRootUrl()
  }

  return (
    <>
      <div
        className="d-flex justify-content-between p-3 fixed-top"
        style={{
          backgroundColor: customizations.onDemandTitleBackgroundColor,
          color: customizations.onDemandTitleTextColor,
        }}
      >
        <div></div>
        <div>{customizations.onDemandTitleText}</div>
        <div className="has-cursor-pointer" onClick={onClickLogout}>
          Logout
        </div>
      </div>
      <div className="pt-5">{children}</div>
    </>
  )
}

const ChoosePlayerPage = ({ accessCode }) => {
  const [players, setPlayers] = useState([])
  const { customizations } = useOnDemandCustomization()

  useEffect(() => {
    async function fetchPlayers() {
      const {
        data: { players },
      } = await axios.get(`${SERVER_ROOT_URL}/${accessCode}/players`)
      setPlayers(players)
    }

    fetchPlayers()
  }, [accessCode])

  if (!customizations.loaded) {
    return <></>
  }

  return (
    <LayoutWithHeader customizations={customizations}>
      <div className="container-fluid">
        <div className="row">
          {players.map(player => (
            <Player key={player.id} player={player} />
          ))}
        </div>
      </div>
    </LayoutWithHeader>
  )
}

const Content = ({ content, searchText, onClick = () => {}, isSelected = false }) => {
  return (
    <div className="col-xs-8 col-xs-offset-2 col-sm-6 col-sm-offset-0 col-md-4 mb-5">
      <div
        className="w-100 has-cursor-pointer"
        style={{
          height: '300px',
          background: `url(${content.thumbnail_url}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
        onClick={onClick}
      >
        {isSelected && (
          <div
            className="w-100 h-100 d-flex justify-content-center align-items-center text-white"
            style={{
              backgroundColor: '#000',
              opacity: '0.8',
            }}
          >
            <i className="fas fa-check-circle fa-4x"></i>
          </div>
        )}
        <div className="position-absolute" style={{ left: '25px', top: '15px' }}>
          <span className="badge badge-dark">
            <i className="far fa-clock mr-2"></i>
            {durationSecondsToString(content.duration, true)}
          </span>
        </div>
      </div>
      <p className="mt-3">{textWithSearchHighlight({ text: content.name, searchText })}</p>
      <p className="mt-3">{textWithSearchHighlight({ text: content.description, searchText })}</p>
    </div>
  )
}

const textWithSearchHighlight = ({ text, searchText }) => {
  if (searchText.length === 0 || !text) {
    return text
  }

  return text
    .split(new RegExp(searchText, 'i'))
    .reduce(
      (result, element, index, parts) =>
        result.concat(element, index < parts.length - 1 ? <span className="bg-warning px-1">{searchText}</span> : null),
      []
    )
}

const PlaybackControls = ({ player_id, selectedContent, accessCode, isPlaying, setIsPlaying, onBeforePressPlay }) => {
  const { customizations } = useOnDemandCustomization()

  const pressKey = async key => {
    // Trigger any task needed to be done before we press play
    if (key === 'PLAY') {
      await onBeforePressPlay()
    }

    const {
      data: { status },
    } = await axios.post(`${SERVER_ROOT_URL}/${accessCode}/key/${player_id}/${key}`)

    if (status === 'ok') {
      if (['STOP', 'PAUSE'].includes(key)) {
        setIsPlaying(false)
      }

      if (key === 'PLAY') {
        setIsPlaying(true)
      }
    }
  }

  return (
    <div
      className="fixed-bottom w-100"
      style={{
        backgroundColor: customizations.onDemandRemoteControlsBgColor,
        color: customizations.onDemandRemoteControlsTextColor,
      }}
    >
      <div className="">
        <h4 className="mt-2 text-center ">{isPlaying ? 'Now Playing' : 'Selected Media'} </h4>
        <h6 className="pb-4 text-center border-bottom border-gray-600">{selectedContent.name}</h6>
        <div className="d-flex justify-content-center">
          <span className="py-4">
            <span className="mr-4 has-cursor-pointer" onClick={() => pressKey('SKIP_BACKWARDS')}>
              <i className={cx('fas fa-backward fa-2x', { 'text-white': isPlaying, 'text-white-50': !isPlaying })}></i>
            </span>
            <span className="mr-4 has-cursor-pointer" onClick={() => pressKey('PLAY')}>
              <i className={cx('fas fa-play fa-2x', { 'text-white': !isPlaying, 'text-white-50': isPlaying })}></i>
            </span>
            <span className="mr-4 has-cursor-pointer" onClick={() => pressKey('STOP')}>
              <i className={cx('fas fa-stop fa-2x', { 'text-white': isPlaying, 'text-white-50': !isPlaying })}></i>
            </span>
            <span className="mr-4 has-cursor-pointer" onClick={() => pressKey('PAUSE')}>
              <i className={cx('fas fa-pause fa-2x', { 'text-white': isPlaying, 'text-white-50': !isPlaying })}></i>
            </span>
            <span className="has-cursor-pointer" onClick={() => pressKey('SKIP_FORWARDS')}>
              <i className={cx('fas fa-forward fa-2x', { 'text-white': isPlaying, 'text-white-50': !isPlaying })}></i>
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

const ChooseContentPage = ({ accessCode }) => {
  const history = useHistory()
  const params = useParams()
  const [isPlaying, setIsPlaying] = useState(false)
  const [player, setPlayer] = useState({})
  const [onDemandContent, setOnDemandContent] = useState([])
  const [selectedContent, setSelectedContent] = useState({ id: 0 })
  const [needsSelectMediaInitialization, setNeedsSelectMediaInitialization] = useState(true)
  const [searchText, setSearchText] = useState('')
  const { customizations } = useOnDemandCustomization()

  const player_id = parseInt(params.id)

  // Fetch all the players and filter out the one corresponding to the current player
  // This is needed only if we are displaying something specific to the player
  useEffect(() => {
    async function fetchPlayer() {
      const {
        data: { players },
      } = await axios.get(`${SERVER_ROOT_URL}/${accessCode}/players`)

      const foundPlayer = players.find(player => player.id === player_id)

      if (foundPlayer) {
        setPlayer(foundPlayer)
      }
    }

    const fetchMedia = async () => {
      const {
        data: { on_demand_content },
      } = await axios.get(`${SERVER_ROOT_URL}/${accessCode}/${player_id}/list-media`)

      if (on_demand_content) {
        setOnDemandContent(on_demand_content)
      }
    }

    fetchPlayer()
    fetchMedia()
  }, [accessCode, player_id])

  // If we are choosing another piece of content, set the playing status to false
  // and reset the needsSelectMediaInitialization flag to true
  useEffect(() => {
    setIsPlaying(false)
    setNeedsSelectMediaInitialization(true)
  }, [selectedContent.id])

  const onBeforePressPlay = async () => {
    if (needsSelectMediaInitialization) {
      // If we need to initialize the media selection, then send that request to the player
      await axios.post(`${SERVER_ROOT_URL}/${accessCode}/${player_id}/select-media/${selectedContent.id}`)
      // and clear the flag
      setNeedsSelectMediaInitialization(false)
    }
  }

  if (!customizations.loaded) {
    return <></>
  }

  const filteredOnDemandContent =
    searchText.length > 0
      ? onDemandContent.filter(content =>
          `${content.name} ${content.description}`.toLowerCase().includes(searchText.toLowerCase())
        )
      : onDemandContent

  return (
    <LayoutWithHeader customizations={customizations}>
      <div className="input-group py-4 px-4">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <i className="fas fa-question-circle"></i>
          </div>
        </div>
        <input
          type="text"
          className="form-control shadowless placeholder-light"
          placeholder="Search"
          value={searchText}
          onChange={event => setSearchText(event.target.value)}
        />
      </div>
      <div className="d-flex justify-content-between text-white bg-secondary p-3" style={{ fontSize: '2rem' }}>
        <span>
          <i
            className="fas fa-chevron-left has-cursor-pointer"
            onClick={() => history.push(`${getClientRootUrl()}/players`)}
          />
        </span>
        <span>{player.name}</span>
        <span></span>
      </div>
      <div className="row mx-4 my-4">
        {filteredOnDemandContent.map(content => (
          <Content
            key={content.id}
            content={content}
            searchText={searchText}
            isSelected={content.id === selectedContent.id}
            onClick={() => setSelectedContent(content)}
          />
        ))}
      </div>
      {selectedContent.id !== 0 && (
        <PlaybackControls {...{ player_id, selectedContent, accessCode, isPlaying, setIsPlaying, onBeforePressPlay }} />
      )}
    </LayoutWithHeader>
  )
}

const NotFound = () => {
  window.location.href = getClientRootUrl()

  return <></>
}

export const OnDemandRemoteControlPage = () => {
  const search = useLocation().search
  const accessCodeFromQueryParams = new URLSearchParams(search).get('accessCode') || ''

  const accessCode = storageForCustomizations().getItem(ON_DEMAND_SECURITY_CODE_ITEM_KEY)

  const [isLoggedIn, setIsLoggedIn] = useState(!!accessCode)

  useEffect(() => {
    setIsLoggedIn(!!accessCode)
  }, [accessCode])

  return (
    <div className="min-vh-100" style={{ backgroundColor: '#EEE' }}>
      <Switch>
        <Route exact path={getClientRootUrl()}>
          <LoginPage accessCodeFromQueryParams={accessCodeFromQueryParams} />
        </Route>
        {isLoggedIn && (
          <Route exact path={`${getClientRootUrl()}/players`}>
            <ChoosePlayerPage accessCode={accessCode} />
          </Route>
        )}
        {isLoggedIn && (
          <Route path={`${getClientRootUrl()}/player/:id`}>
            <ChooseContentPage accessCode={accessCode} />
          </Route>
        )}
        <NotFound />
      </Switch>
    </div>
  )
}
