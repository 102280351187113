import { MiddlewareStack } from 'spraypaint'
import { getCSRFToken } from './Auth'
import { ApplicationRecord } from 'neocast-api-js-client'
import { loginPath } from 'Routes'
import { recordClientTimeoutSecondsRemainingFromResponse, saveLoginRedirectInfo } from './loggedInStatus'
import { isProduction } from 'env'

const middleware = new MiddlewareStack()
middleware.beforeFilters.push((url, options) => {
  // CSRF Tokens are not needed for GET requests
  if (options.method !== 'GET') {
    // @ts-ignore
    options.headers['X-CSRF-Token'] = getCSRFToken()
  }
})

export function jsonToErrorId(json: any) {
  if (json?.errors?.map) {
    return json.errors.map((error: any) => error?.meta?.errorId)[0]
  } else {
    return ''
  }
}

middleware.afterFilters.push(async (response, _) => {
  if (response.status === 500) {
    const json = await response.clone().json()

    const id = jsonToErrorId(json)

    if (isProduction) {
      // And route the user to the error page
      window.location.href = `/error/${id}`
    }
  }

  if (response.status === 401 && !window.location.href.includes(loginPath())) {
    // Store away the current pathname and search
    saveLoginRedirectInfo()

    window.location.href = loginPath()
    throw new Error('abort')
  }

  recordClientTimeoutSecondsRemainingFromResponse(response)
})

ApplicationRecord.middlewareStack = middleware
