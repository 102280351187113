import React, { useState, useEffect } from 'react'

const GracefulImage = ({
  alt,
  width = undefined,
  height = undefined,
  className = undefined,
  src,
  errorImage = undefined,
}: {
  alt?: string
  width?: number
  height?: number
  className?: string
  src: string
  errorImage?: string
}) => {
  const [currentSrc, setCurrentSrc] = useState(src)
  const [error, setError] = useState(false)

  const onImageError = () => {
    setCurrentSrc(
      errorImage ||
        `data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' width%3D'${width}' height%3D'${height}' viewBox%3D'0 0 ${width} ${height}'%2F%3E`
    )
    setError(true)
  }

  useEffect(() => {
    setCurrentSrc(src)
  }, [src])

  return (
    <img
      alt={alt}
      width={width}
      height={height}
      className={className}
      src={currentSrc}
      onError={onImageError}
      style={{ backgroundColor: error ? '#ccc' : 'transparent' }}
    />
  )
}

export default GracefulImage
