import { useEffect, useRef } from 'react'

export function useAutoFocus({ deps = [], autoSelect = false }: { deps?: any[]; autoSelect?: boolean }) {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // Focus the input element 100 ms after the component mounts
    // close enough for horseshoes and hand grenades
    const timeout = setTimeout(() => {
      if (ref.current) {
        ref.current.focus()
        if (autoSelect) {
          ref.current.select()
        }
        clearTimeout(timeout)
      }
    }, 100)

    // Ensure we clear the timeout
    return () => clearTimeout(timeout)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return ref
}
