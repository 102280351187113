import { RoutePathFunction } from './types'
import { paramsReplace } from './utils'

export const resetPasswordPath: RoutePathFunction = params => paramsReplace('/reset-password/:token', params)

export const forgotMyPasswordPath: RoutePathFunction = params => paramsReplace('/forgot-my-password', params)

export const editUserPath: RoutePathFunction = params => paramsReplace('/users/:id', params)

export const userIndexPath: RoutePathFunction = params => paramsReplace('/users', params)

export const userIndexShowTypePath: RoutePathFunction = params => paramsReplace('/users/show/:user_type', params)

export const accountSettingsPath: RoutePathFunction = params => {
  return paramsReplace('/account-settings', params)
}
export const allUserPaths = [userIndexPath(), editUserPath(), userIndexShowTypePath()]
