import { IndexTableColumn } from 'components/Utils/TableIndexColumn'

// TODO: Determine better types here

export function computeIncludesHash({
  columns,
  userSelectedColumnKeys,
}: {
  columns: IndexTableColumn[]
  userSelectedColumnKeys: string[]
}): any[] {
  const includes = columns
    .filter(column => userSelectedColumnKeys.includes(column.key()))
    .map(column => column.includes)
    .filter(Boolean)
    .flat()

  let results: any[] = []

  includes.forEach(specificInclude => {
    if (
      // JSONify each element in results and see if this specific include is inside (stringify to get a sane equals)
      !results.map(result => JSON.stringify(result)).includes(JSON.stringify(specificInclude)) &&
      // Or if the specific include is exists as any key to any existing result, skip it. This is for a case where we have { location: 'address' } in results already, but we need to add 'location' itself
      // @ts-ignore
      !results.some(result => result[specificInclude])
    ) {
      results = [...results, specificInclude]
    }
  })

  return results
}
