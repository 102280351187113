import React, { useState } from 'react'
import { formToObject } from 'utils/formToObject'
import neocastLogo from 'images/neocast-logo.svg'
import { loginPath } from 'Routes'
import { Translate } from 'components/Utils/Translate'
import { translateRaw } from 'components/Utils/translateRaw'
import { submitResetPasswordRequest } from 'components/BackendAPI/BackendAPI'
import { Modal } from 'neocast-bootstrap'
import { User } from 'neocast-api-js-client'
import { SuperModal } from 'components/Utils/SuperModal'

export const ForgotMyPasswordForm = () => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = formToObject(form, new User())

    submitResetPasswordRequest({ email: formData.email }).then(() => {
      setConfirmationModalOpen(true)
    })
  }

  const onOk = () => {
    window.location.pathname = loginPath()
  }

  return (
    <div className="full-dialog-page d-flex align-items-center">
      <SuperModal>
        {({ onEnterKeyRef }) => (
          <Modal centered isOpen={confirmationModalOpen}>
            <div className="modal-header bg-offwhite">{Translate('forgot-password-confirmation-dialog-title')}</div>
            <div className="modal-body">{Translate('forgot-password-confirmation-dialog-body')}</div>
            <div className="modal-footer">
              <button ref={onEnterKeyRef} type="button" className="btn btn-primary" onClick={onOk}>
                {Translate('forgot-password-confirmation-dialog-ok')}
              </button>
            </div>
          </Modal>
        )}
      </SuperModal>

      <div className="flex-grow-1">
        <div>
          <div className="col-4 col-md-2 mx-auto">
            <img alt="NEOCAST logo" className="w-100 mb-3" src={neocastLogo} />
          </div>
        </div>
        <div>
          <div className="col-8 col-md-3 mx-auto">
            <form className="form" autoComplete="off" onSubmit={onSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  autoFocus
                  placeholder={translateRaw('forgot-password-page-email')}
                  className="form-control form-control-lg"
                  name="email"
                  id="email"
                />
              </div>
              <div>
                <button type="submit" className="w-100 btn btn-primary btn-lg" id="btnLogin">
                  {Translate('forgot-password-page-buttons-send')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
