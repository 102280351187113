import { useAutoFocus } from 'hooks'
import React, { ReactNode, useRef } from 'react'

export function SuperModal({
  enterKeySubmitsDisabled = false,
  escapeKeyCloses = true,
  autoSelect = true,
  autoFocusDeps = [],
  children,
}: {
  enterKeySubmitsDisabled?: boolean
  escapeKeyCloses?: boolean
  autoSelect?: boolean
  autoFocusDeps?: any[]
  children: ({
    onEnterKeyRef,
    onEscapeKeyRef,
  }: {
    onEnterKeyRef: React.RefObject<HTMLButtonElement>
    onEscapeKeyRef: React.RefObject<HTMLButtonElement>
    autoFocusRef: React.RefObject<HTMLInputElement>
  }) => ReactNode
}) {
  const onEnterKeyRef = useRef<HTMLButtonElement>(null)
  const onEscapeKeyRef = useRef<HTMLButtonElement>(null)
  const autoFocusRef = useAutoFocus({ autoSelect, deps: autoFocusDeps })

  return (
    <div
      onKeyDown={event => {
        if (event.key === 'Enter' && onEnterKeyRef?.current) {
          event.stopPropagation()
          if (enterKeySubmitsDisabled || !onEnterKeyRef.current.disabled) {
            onEnterKeyRef.current.click()
          }
        }

        if (event.key === 'Escape' && onEscapeKeyRef?.current) {
          event.stopPropagation()
          if (escapeKeyCloses) {
            onEscapeKeyRef.current.click()
          }
        }
      }}
    >
      {children({ onEnterKeyRef, onEscapeKeyRef, autoFocusRef })}
    </div>
  )
}
