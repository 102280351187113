import React from 'react'

export class RecordNotFoundError extends Error {
  constructor() {
    super('')

    this.name = 'RecordNotFoundError'
  }
}

export class FourOhFourBoundary extends React.Component {
  state = {
    hasError: false,
  }

  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: error.name === 'RecordNotFoundError' }
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return <FourOhFourNotFound />
    }

    return this.props.children
  }
}

export function FourOhFourNotFound() {
  return (
    <div className="error-page">
      <div className="outer-box">
        <div className="header">404 - Page Not Found</div>
        <div className="body">
          <p>
            The page you requested cannot be found within NEOCAST<sup>&reg;</sup>. Please return to the application.
          </p>

          <p>
            <a href="/" className="btn">
              Return to Application
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
