import { mediaIndexPath } from './mediaRoutes'
import { playerIndexPath } from './playerRoutes'
import { presentationIndexPath } from './presentationRoutes'
import { RoutePathFunction } from './types'
import { paramsReplace } from './utils'

// eslint-disable-next-line react-refresh/only-export-components
export * from './playerRoutes'

// eslint-disable-next-line react-refresh/only-export-components
export * from './mediaRoutes'

// eslint-disable-next-line react-refresh/only-export-components
export * from './userRoutes'

// eslint-disable-next-line react-refresh/only-export-components
export * from './presentationRoutes'

// eslint-disable-next-line react-refresh/only-export-components
export * from './listFromRoutes'

export const logoutPath: RoutePathFunction = params => paramsReplace('/logout', params)

export const loginPath: RoutePathFunction = params => paramsReplace('/login', params)

export const homePath: RoutePathFunction = params => paramsReplace('/', params)

export const configureSitePath: RoutePathFunction = params => paramsReplace('/configure-site/:section', params)

export const onDemandRemotePath: RoutePathFunction = params => {
  return paramsReplace('/on-demand-remote', params)
}

export const modelSelectorSearchPath: RoutePathFunction = () => {
  return '/api/v1/model_selector/search'
}

export const modelSelectorRecentPath: RoutePathFunction = () => {
  return '/api/v1/model_selector/recent'
}

export const thumbnailGeneratePath: RoutePathFunction = params => {
  return paramsReplace('/thumbnails/generate/:id/:thumbnailOffset', params)
}

export const indexPathForType = (type: string, params = {}) => {
  switch (type) {
    case 'Player':
      return playerIndexPath(params)
    case 'Media':
      return mediaIndexPath(params)
    case 'Presentation':
      return presentationIndexPath(params)
    default:
      return homePath(params)
  }
}
