import React from 'react'
import { TranslationKey } from 'static-data/TranslationKey'
import { translateRaw } from './translateRaw'

const TranslationElement = ({ translationKey, options = {} }: { translationKey: TranslationKey; options?: object }) => {
  return (
    <span className="neocast-translation" data-translation-key={translationKey}>
      {translateRaw(translationKey, options)}
    </span>
  )
}

export const Translate = (key: TranslationKey, options?: object) => {
  return <TranslationElement translationKey={key} options={options} />
}
