import React from 'react'
import neocastLogo from 'images/neocast-logo.svg'
import { useParams } from 'react-router'

export const RouteErrorComponent = () => {
  const params = useParams<{ id: string }>()
  const id = params.id

  return <DefaultErrorComponent id={id} />
}

export const DefaultErrorComponent = ({ id }: { id?: string }) => {
  return (
    <div className="full-dialog-page d-flex align-items-center">
      <div className="flex-grow-1">
        <div>
          <div className="col-4 col-md-2 mx-auto">
            <img alt="NEOCAST logo" className="w-100 mb-3" src={neocastLogo} />
          </div>
        </div>
        <div>
          <div className="col-8 col-md-4 mx-auto">
            <div className="card border-radius-point-3-rem">
              <div className="card-body">
                <form>
                  <h1>Hmmm...</h1>
                  <p>
                    There was an error processing your request.
                    <br />
                    We are looking into it.
                  </p>
                  <a href="/" className="w-100 btn btn-primary btn-lg mb-3">
                    Return to Application
                  </a>
                  {id ? (
                    <p>
                      ID: <a href={`https://app.honeybadger.io/notice/${id}`}>{id}</a>
                    </p>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
