import { homePath } from 'Routes'
import { getCookie } from '.'
import { clearLoggedIn } from './loggedInStatus'

export const logout = ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
  fetch('/auth/logout', {
    method: 'DELETE',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  })
    .then(onSuccess)
    .catch(onError)
}

export const login = ({
  user,
  onSuccess,
  onError,
}: {
  user: { login: string; password: string }
  onSuccess: () => void
  onError: () => void
}) => {
  fetch('/auth/login', {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ user }),
  }).then(response => {
    if (response.status === 200) {
      onSuccess()
    } else {
      onError()
    }
  })
}

export const getCSRFToken = () => {
  if (typeof getCookie !== 'undefined') {
    return getCookie('CSRF-TOKEN') || ''
  } else {
    return ''
  }
}

export const performLogout = () => {
  // Ensure we clear the login status always
  clearLoggedIn()

  // Redirect the user
  function redirect() {
    window.location.assign(homePath())
  }

  // Redirect the user regardless of success or error coming from the server
  logout({
    onSuccess: redirect,
    onError: redirect,
  })
}
