import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { LoginForm } from 'components/Login/LoginForm'
import { ForgotMyPasswordForm } from 'components/Login/ForgotMyPasswordForm'
import { ResetPasswordForm } from 'components/Login/ResetPasswordForm'
import { Logout } from 'Logout'
import { OnDemandRemoteControlPage } from 'components/OnDemandRemoteControl'
import { RouteErrorComponent } from 'DefaultErrorComponent'
import { AuthenticatedAppWrapper } from './AuthenticatedAppWrapper'
import { usePreviousLocationContext } from 'components/Utils/usePreviousLocationContext'
import { loginPath, resetPasswordPath, forgotMyPasswordPath, logoutPath, onDemandRemotePath } from 'Routes'

export const RouteComponent = () => {
  const { setCurrentLocation } = usePreviousLocationContext()
  const location = useLocation()

  setCurrentLocation(location)

  return (
    <Switch>
      <Route path="/error/:id?">
        <RouteErrorComponent />
      </Route>
      <Route path={loginPath()}>
        <LoginForm />
      </Route>
      <Route path={resetPasswordPath()}>
        <ResetPasswordForm />
      </Route>
      <Route path={forgotMyPasswordPath()}>
        <ForgotMyPasswordForm />
      </Route>
      <Route path={logoutPath()}>
        <Logout />
      </Route>
      <Route path={onDemandRemotePath()}>
        <OnDemandRemoteControlPage />
      </Route>
      <Route>
        <AuthenticatedAppWrapper />
      </Route>
    </Switch>
  )
}
