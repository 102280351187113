import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import App from './App'
import { getCSRFToken } from './utils/Auth'
import axios from 'axios'
import Honeybadger from '@honeybadger-io/js'
import { DefaultErrorComponent } from './DefaultErrorComponent'
import ErrorBoundary from './components/Utils/ErrorBoundary'
import translations from './static-data/translations.json'

import './utils/spraypaint-middleware'
import { NODE_ENV, CommitSHA, isProduction } from 'env'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

axios.defaults.headers.common['X-CSRF-Token'] = getCSRFToken()

i18next.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
})
i18next.addResourceBundle('en', 'translation', translations)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
})

const root = document.getElementById('root')

if (root) {
  if (isProduction) {
    const honeybadger = Honeybadger.configure({
      apiKey: root.dataset.honeybadgerApikey,
      environment: NODE_ENV,
      revision: CommitSHA,
    })

    // Ensure the front end tag is set
    honeybadger.setContext({ tags: 'frontend' })

    // Setup a before notify to skip certain messages.
    honeybadger.beforeNotify(notice => {
      if (notice.message.includes('ResizeObserver loop')) {
        return false
      }
    })

    ReactDOM.render(
      <ErrorBoundary honeybadger={honeybadger} ErrorComponent={DefaultErrorComponent}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <App />
          </Router>
        </QueryClientProvider>
      </ErrorBoundary>,
      root
    )
  } else {
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>,
      root
    )
  }
}
