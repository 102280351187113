import React, { lazy, Suspense } from 'react'
import { Redirect } from 'react-router-dom'
import { getLoggedIn, saveLoginRedirectInfo } from './utils/loggedInStatus'
import { loginPath } from './Routes'
import { useUserProfile } from 'hooks'
import { ExpiredAccountPage } from 'ExpiredAccountPage'

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'))
const AuthenticatedRoutes = lazy(() => import('./AuthenticatedRoutes'))

export const AuthenticatedAppWrapper = () => {
  const loggedIn = getLoggedIn()
  const { user } = useUserProfile()

  if (!loggedIn) {
    saveLoginRedirectInfo()

    return <Redirect to={loginPath()} />
  }

  if (user.customerIsExpired) {
    return <ExpiredAccountPage />
  }

  return (
    <Suspense fallback={<div></div>}>
      <AuthenticatedApp>
        <AuthenticatedRoutes />
      </AuthenticatedApp>
    </Suspense>
  )
}
