import { PreviousLocationWrapper } from 'components/Utils/PreviousLocationContext'
import React from 'react'

import { RouteComponent } from 'RouteComponent'

import './styles/index.scss'
import './styles/fontawesome/css/all.css'

const App = () => (
  <PreviousLocationWrapper>
    <RouteComponent />
  </PreviousLocationWrapper>
)

export default App
