//
// This provides a context to track the user's last location in the app.
//
// We do this by storing some session information of the previous location
// and the current location. Each time the router renders a new route we
// move the current location to previous and store this new current location.
//
// Users of this context will focus on `goBack` which will do a history push
// to the last location as well as getPreviousLocation (if they wish to inspect
// the previous location) -- setCurrentLocation is to only be used by the
// Routes component to call with the current react router location object.
//
import React, { createContext, ReactNode } from 'react'
import { useHistory } from 'react-router'
import { homePath } from 'Routes'

export type PreviousLocation = {
  pathname: string
  search: string
  hash: string
  key?: string
}

function homeLocation() {
  return { pathname: homePath(), search: '', hash: '', key: '' }
}

export const PreviousLocationContext = createContext({
  getPreviousLocation: () => homeLocation(),
  setCurrentLocation: (_: PreviousLocation) => {},
  goBack: () => {},
})

export const PreviousLocationWrapper = ({ children }: { children: ReactNode }) => {
  const history = useHistory()

  const getPreviousLocation = () =>
    JSON.parse(sessionStorage.getItem('previousLocation') || JSON.stringify(homeLocation()))

  const goBack = () => {
    const url = getPreviousLocation().pathname + getPreviousLocation().search

    history.push(url)
  }

  const setCurrentLocation = (location: PreviousLocation) => {
    const currentLocation = JSON.parse(sessionStorage.getItem('currentLocation') || '{}')

    // Only move current to previous and the new location to "current" if the
    // location key's differ (e.g. this is a new navigation)
    if (location.key !== currentLocation.key) {
      sessionStorage.setItem('previousLocation', JSON.stringify(currentLocation))
      sessionStorage.setItem('currentLocation', JSON.stringify(location))
    }
  }

  return (
    <PreviousLocationContext.Provider value={{ goBack, getPreviousLocation, setCurrentLocation }}>
      {children}
    </PreviousLocationContext.Provider>
  )
}
