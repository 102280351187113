import React from 'react'
import neocastLogo from 'images/neocast-logo.svg'
import { Translate } from 'components/Utils/Translate'
import { useParams } from 'react-router'
import { formToObject } from 'utils/formToObject'
import { submitResetPassword } from 'components/BackendAPI/BackendAPI'
import { loginPath } from 'Routes'
import { ResetPassword } from 'neocast-api-js-client'

export const ResetPasswordForm = () => {
  const { token } = useParams<{ token: string }>()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = formToObject(form, new ResetPassword())

    submitResetPassword({
      token,
      password: formData.password,
      passwordConfirmation: formData.passwordConfirmation,
    }).then(() => {
      window.location.pathname = loginPath()
    })
  }

  return (
    <div className="full-dialog-page d-flex align-items-center">
      <div className="flex-grow-1">
        <div>
          <div className="col-4 col-md-2 mx-auto">
            <img alt="NEOCAST logo" className="w-100 mb-3" src={neocastLogo} />
          </div>
        </div>
        <div>
          <div className="col-8 col-md-4 mx-auto">
            <div className="card border-radius-point-3-rem">
              <div className="card-body">
                <form className="form" autoComplete="off" onSubmit={onSubmit}>
                  <div className="form-group">
                    <label htmlFor="password">{Translate('reset-password-page-password')}</label>
                    <input
                      type="password"
                      autoFocus
                      className="form-control form-control-lg"
                      name="password"
                      id="password"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password-confirmation">
                      {Translate('reset-password-page-password-confirmation')}
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      name="passwordConfirmation"
                      id="passwordConfirmation"
                    />
                  </div>

                  <div>
                    <button type="submit" className="w-100 btn btn-primary btn-lg" id="btnLogin">
                      {Translate('reset-password-page-buttons-send')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
