import { CurrentUser, Preference } from 'neocast-api-js-client'
import { createContext, useContext } from 'react'

export const defaultUserProfile = {
  sidebarOpen: true,
  toggleSidebar: () => {},
  user: new CurrentUser(),
  reload: () => {},
  preference: new Preference(),
}

export const UserProfileContext = createContext(defaultUserProfile)
export const useUserProfile = () => useContext(UserProfileContext)
