import React, { Component } from 'react'
import { DefaultErrorComponent } from '../../DefaultErrorComponent'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      info: null,
      errorOccurred: false,
      id: null,
    }
  }

  static getDerivedStateFromError(error) {
    return { errorOccurred: true, error: error }
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true, error: error, info: info })

    if (this.props.honeybadger) {
      error.afterNotify = this.props.honeybadger.notify(error, {
        afterNotify: (err, notice) => {
          console.log(`Honeybadger error number ${notice.id}`)
          this.setState({ id: notice.id })
        },
        context: info,
      })
    }
  }

  render() {
    if (this.state.errorOccurred) {
      console.log({ msg: 'xxxxxxxxx', error: this.state.error, info: this.state.info })
      const { ErrorComponent } = this.props
      return ErrorComponent ? React.createElement(ErrorComponent, this.state) : <DefaultErrorComponent />
    } else {
      return this.props.children
    }
  }
}
