import React from 'react'

export const ExpiredAccountPage = () => {
  return (
    <div className="error-page">
      <div className="outer-box">
        <div className="header">Site Expired</div>
        <div className="body">
          <p>
            Your evaluation period to access NEOCAST® has expired. To extend the evaluation or discuss purchasing
            options, please contact support.
          </p>

          <p>
            <a href="mailto:support@realdigitalmedia.com?subject=NEOCAST Evaluation" className="btn">
              Contact Support
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
