import {
  editMediaPath,
  editMediaGroupPath,
  editSmartGroupPath,
  editPresentationPath,
  editUserPath,
  allPresentationPaths,
  allMediaPaths,
  allUserPaths,
  allPlayerPaths,
  editLocationPath,
  editNetworkPath,
  editPlayerGroupPath,
  editPlayerPath,
} from 'Routes'
import { matchPath } from 'react-router'

// If this isn't a `function`, the `playerIndexPath` can't see it. Weird.
export function paramsReplace(path: string, params: Record<string, any> = {}) {
  return (
    Object.entries(params)
      // Turn the params into an array of tuples
      .map(([param, value]) => [`:${param}`, value])
      // Starting with the full path string, reduce down each pair of param/value tuple by replacing the param with the value
      .reduce((replacedPath, [param, value]) => replacedPath.replaceAll(param, value), path)
      // Reduce any double slashes to a single slash
      .replaceAll('//', '/')
  )
}
export function getPolymorphicPath(object: { modelClassName: string; id: string; targetClass?: string }) {
  switch (object.modelClassName) {
    case 'Player':
      return editPlayerPath({ id: object.id })
    case 'Location':
      return editLocationPath({ id: object.id })
    case 'Network':
      return editNetworkPath({ id: object.id })
    case 'Media':
      return editMediaPath({ id: object.id })
    case 'MediaGroup':
      return editMediaGroupPath({ id: object.id })
    case 'PlayerGroup':
      return editPlayerGroupPath({ id: object.id })
    case 'SmartGroup':
      return editSmartGroupPath({ id: object.id, type: (object.targetClass || '').toLowerCase() })
    case 'Presentation':
      return editPresentationPath({ id: object.id })
    case 'User':
      return editUserPath({ id: object.id })
    default:
      return ''
  }
}
export function isPresentationPath(pathname: string) {
  return isPathMatch(pathname, allPresentationPaths)
}

export function isPlayerPath(pathname: string) {
  return isPathMatch(pathname, allPlayerPaths)
}

export function isMediaPath(pathname: string) {
  return isPathMatch(pathname, allMediaPaths)
}

export function isUserPath(pathname: string) {
  return isPathMatch(pathname, allUserPaths)
}

export function isHomePath(pathname: string) {
  return isPathMatch(pathname, ['/'])
}
export function isPathMatch(pathname: string, paths: string[]) {
  return paths.some(path => {
    const match = matchPath(pathname, { path, exact: true })
    return match
  })
}
