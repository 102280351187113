import { useState } from 'react'
import { useInterval } from 'hooks'
import axios from 'axios'

export const useReloadAppWhenNeeded = () => {
  const [shouldFetchManifest, setShouldFetchManifest] = useState(true)
  const [appReloadIsNeeded, setAppReloadIsNeeded] = useState(false)

  useInterval(async () => {
    async function handleInterval() {
      const manifest = await axios({ url: '/asset-manifest.json', method: 'HEAD' })

      const existingManifestEtag = localStorage.getItem('asset-manifest-etag')
      const currentManifestEtag = manifest.headers['etag']

      localStorage.setItem('asset-manifest-etag', currentManifestEtag)

      if (existingManifestEtag && existingManifestEtag !== currentManifestEtag) {
        console.debug(
          `%cNoted an asset-manifest etag change%c %cexisting=${existingManifestEtag} %ccurrent=${currentManifestEtag}`,
          'font-weight:bold',
          'font-weight:normal',
          'color: green',
          'color: orange'
        )
        setAppReloadIsNeeded(true)
      } else {
        console.debug('No asset-manifest etag change')
      }
    }

    try {
      if (shouldFetchManifest) {
        await handleInterval()
      }
    } catch {
      console.debug('Encountered an error when fetching /asset-manifest.json - stopping the fetch cycle.')
      setShouldFetchManifest(false)
    }
  }, 60 * 1000)

  return appReloadIsNeeded
}
